import {
  SeoConfigVariant,
  SeoResponse,
  cleanFalsyProps,
  seoBodyConfigQueries
} from '@/entities/seo';

import { OperationVariables, useQuery } from '@apollo/client';

export function useSeoConfig<T extends SeoConfigVariant>(
  seoConfigType: T,
  operationVariables?: OperationVariables
) {
  const { loading, error, data } = useQuery<SeoResponse<T>>(
    seoBodyConfigQueries[seoConfigType],
    operationVariables
  );

  const result = data?.[seoConfigType];

  return {
    error,
    loading,
    seo: result?.success ? cleanFalsyProps(result.data) : null,
    fetched: !!data || !!error
  };
}

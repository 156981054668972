import { DocumentNode } from 'graphql';
import {
  getSeoBodyDefaultGameQuery,
  getSeoBodyDefaultSports,
  getSeoBodyDefaultSportsEvent,
  getSeoBodyDefaultTournament,
  getSeoBodyHomeQuery,
  getSeoBodyLobbyQuery,
  getSeoBodySportsBookUrl,
  getSeoMetaDefaultGameQuery,
  getSeoMetaDefaultSports,
  getSeoMetaDefaultSportsEvent,
  getSeoMetaDefaultTournament,
  getSeoMetaHomeQuery,
  getSeoMetaLobbyQuery,
  getSeoMetaSportsBookUrl
} from '../api';
import { SeoConfigVariant } from '../types';

export const seoMetaConfigQueries: { [key in SeoConfigVariant]: DocumentNode } = {
  seoLobbyConfig: getSeoMetaLobbyQuery,
  seoHomeConfig: getSeoMetaHomeQuery,
  seoDefaultGameConfig: getSeoMetaDefaultGameQuery,
  seoSportsBookUrl: getSeoMetaSportsBookUrl,
  seoDefaultSportsEvent: getSeoMetaDefaultSportsEvent,
  seoDefaultTournament: getSeoMetaDefaultTournament,
  seoDefaultSports: getSeoMetaDefaultSports
};

export const seoBodyConfigQueries: { [key in SeoConfigVariant]: DocumentNode } = {
  seoLobbyConfig: getSeoBodyLobbyQuery,
  seoHomeConfig: getSeoBodyHomeQuery,
  seoDefaultGameConfig: getSeoBodyDefaultGameQuery,
  seoSportsBookUrl: getSeoBodySportsBookUrl,
  seoDefaultSportsEvent: getSeoBodyDefaultSportsEvent,
  seoDefaultTournament: getSeoBodyDefaultTournament,
  seoDefaultSports: getSeoBodyDefaultSports
};

import { useProfile } from '@/entities/profile';
import { useQuery } from '@apollo/client';
import { getFavoritesQuery } from '@/entities/favorite/api';
import { useAuth } from '@/entities/auth';

export const useFavorites = () => {
  const { profile, pending: profilePendig } = useProfile();
  const { ready: authReady } = useAuth();
  const showFavorites = Boolean(profile);
  const { data, loading } = useQuery<{ getFavorites: { favorites: string[] } }>(getFavoritesQuery, {
    fetchPolicy: 'no-cache',
    skip: !showFavorites
  });
  const ids = data?.getFavorites.favorites ?? [];

  return {
    ids,
    loading: !authReady || profilePendig || loading
  };
};

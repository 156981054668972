'use client';

import { useQuery } from '@apollo/client';

import { Device, GamesPane, GamesPaneSkeleton, gamesQuery, GamesResponse } from '@/entities/game';
import { RoutesConfig } from '@/shared/routes/config';
import { useAuth } from '@/entities/auth';
import { useScopedI18n } from '@/shared/locales/client';

import { sortGamesByIds } from '../lib/sort-games-by-ids';
import { useFavorites } from '../lib/use-favorites';

type Props = {
  className?: string;
  device: Device;
  doubleRow?: boolean;
};

export const FavoriteGamesPreviewPane: React.FC<Props> = ({ className, device, doubleRow }) => {
  const t = useScopedI18n('lobby');
  const { ids, loading: idsLoading } = useFavorites();
  const { isAuth, ready: authReady } = useAuth();
  const { data, loading: gamesLoading } = useQuery<GamesResponse>(gamesQuery, {
    variables: {
      input: {
        id: [...ids],
        devices: [device],
        limit: ids.length
      }
    },
    skip: !isAuth || ids.length === 0,
    fetchPolicy: 'no-cache'
  });

  const games = sortGamesByIds(ids, (data && data.games.success && data.games.items) || []);

  const notAuthed = authReady && !isAuth;
  const noGames = !games.length && !idsLoading && !gamesLoading;

  if ((idsLoading || gamesLoading) && device === 'desktop')
    return <GamesPaneSkeleton className={className} />;

  if (notAuthed || noGames) return null;

  return (
    <GamesPane
      device={device}
      total={data?.games.success ? data.games.total : 0}
      id="favorites"
      games={games}
      href={`${RoutesConfig.casinoCategories}/favorites`}
      title={t('favorites')}
      className={className}
      doubleRow={doubleRow}
      argsForDataTestId={{
        nameForTargetBlock: 'categoryPreviewPane',
        modification: 'favorites'
      }}
    />
  );
};

import { Game } from '@/entities/game';

export function sortGamesByIds(ids: string[], games: Game[]) {
  const hash: Record<string, Game> = {};
  games.forEach((item) => (hash[item.id] = item));

  return ids.reduce((sortedGames, id) => {
    if (hash[id]) {
      sortedGames.push(hash[id]);
    }
    return sortedGames;
  }, [] as Game[]);
}

import { gql } from '@apollo/client';

export const SeoMetaFieldsFragment = gql`
  fragment SeoMetaFields on SeoConfig {
    ogImage {
      url
      alt
    }
    metaTitle
    metaDescription
  }
`;

export const SeoBodyFieldsFragment = gql`
  fragment SeoBodyFields on SeoConfig {
    seoText
    titleH1
  }
`;

export const SeoSuccessInfoDescriptor = `
    success
    error {
      message
    }
`;

export const getSeoMetaLobbyQuery = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaLobbyConfig {
    seoLobbyConfig {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodyLobbyQuery = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodyLobbyConfig {
    seoLobbyConfig {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

export const getSeoMetaHomeQuery = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaHomeConfig {
    seoHomeConfig {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodyHomeQuery = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodyHomeConfig {
    seoHomeConfig {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

export const getSeoMetaDefaultGameQuery = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaDefaultGameConfig {
    seoDefaultGameConfig {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodyDefaultGameQuery = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodyDefaultGameConfig {
    seoDefaultGameConfig {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

export const getSeoMetaSportsBookUrl = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaSportsBookUrl($url: String!) {
    seoSportsBookUrl(url: $url) {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodySportsBookUrl = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodySportsBookUrl($url: String!) {
    seoSportsBookUrl(url: $url) {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

export const getSeoMetaDefaultSports = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaDefaultSports($betType: BetType!) {
    seoDefaultSports(betType: $betType) {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodyDefaultSports = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodyDefaultSports($betType: BetType!) {
    seoDefaultSports(betType: $betType) {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

export const getSeoMetaDefaultSportsEvent = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaDefaultSportsEvent {
    seoDefaultSportsEvent {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodyDefaultSportsEvent = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodyDefaultSportsEvent {
    seoDefaultSportsEvent {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

export const getSeoMetaDefaultTournament = gql`
  ${SeoMetaFieldsFragment}
  query GetSeoMetaDefaultTournament {
    seoDefaultTournament {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoMetaFields
      }
    }
  }
`;

export const getSeoBodyDefaultTournament = gql`
  ${SeoBodyFieldsFragment}
  query GetSeoBodyDefaultTournament {
    seoDefaultTournament {
      ${SeoSuccessInfoDescriptor}
      data {
        ...SeoBodyFields
      }
    }
  }
`;

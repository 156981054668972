'use client';

import React from 'react';
import Link from 'next/link';
import { useQuery } from '@apollo/client';

import { Device, GameCard, gamesQuery, GamesResponse } from '@/entities/game';
import { useScopedI18n } from '@/shared/locales/client';
import Heading from '@/features/home/ui/Heading';
import { RoutesConfig } from '@/shared/routes/config';
import { Skeleton } from '@/shared/ui';
import { repeatComponent } from '@/shared/lib/repeat-component';
import { useAuth } from '@/entities/auth';

import { useFavorites } from '../lib/use-favorites';
import { sortGamesByIds } from '../lib/sort-games-by-ids';

type Props = {
  className?: string;
  device: Device;
  additionalCategory?: React.ReactNode;
};

export const FavoriteGames = ({ device, additionalCategory }: Props) => {
  const { ids, loading } = useFavorites();
  const t = useScopedI18n('lobby');

  return (
    <>
      <Heading>{t('favorites')}</Heading>
      <FavoriteGamesList ids={ids} idsLoading={loading} device={device} />
      {!loading ? additionalCategory : null}
    </>
  );
};

type FavoriteGamesListProps = Props & {
  ids: string[];
  idsLoading: boolean;
};

const FavoriteGamesList = ({ device, ids, idsLoading }: FavoriteGamesListProps) => {
  const t = useScopedI18n('lobby');
  const { isAuth, ready: authReady } = useAuth();
  const { data, loading } = useQuery<GamesResponse>(gamesQuery, {
    variables: {
      input: {
        id: [...ids],
        devices: [device],
        limit: ids.length
      }
    },
    skip: !isAuth || ids.length === 0,
    fetchPolicy: 'no-cache'
  });

  const games = sortGamesByIds(ids, (data && data.games.success && data.games.items) || []);

  if (authReady && !isAuth) {
    return (
      <div className="mb-6t text-xl text-grey-light">
        {t('favorites.notAuth')}&nbsp;
        <Link className="text-primary underline" href={`${RoutesConfig.login}`}>
          {t('favorites.notAuthLink')}
        </Link>
      </div>
    );
  }

  if (!games.length && !idsLoading && !loading) {
    return <div className="text-grey-light">{t('favorites.notFound')}</div>;
  }

  return (
    <div className="grid w-full grid-cols-3 gap-1t md:grid-cols-auto-fill-games lg:gap-2t">
      {loading || idsLoading ? (
        <>
          {repeatComponent(
            <Skeleton className="max-w-18t aspect-square w-full flex-none rounded-md" />,
            24
          )}
        </>
      ) : (
        <>
          {games.map((data) => (
            <GameCard data={data} key={data.id} autoSize />
          ))}
        </>
      )}
    </div>
  );
};

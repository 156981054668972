'use client';

import { useQuery } from '@apollo/client';

import { Device, GamesPaneSkeleton, gamesQuery, GamesResponse, GamesTiles } from '@/entities/game';
import { RoutesConfig } from '@/shared/routes/config';
import { useScopedI18n } from '@/shared/locales/client';
import { useAuth } from '@/entities/auth';

import { useFavorites } from '../lib/use-favorites';
import { sortGamesByIds } from '../lib/sort-games-by-ids';

type Props = {
  className?: string;
  device: Device;
};

export const FavoriteGamesPreviewTiles: React.FC<Props> = ({ className, device }) => {
  const t = useScopedI18n('lobby');
  const { ids, loading: idsLoading } = useFavorites();
  const { isAuth, ready: authReady } = useAuth();
  const { data, loading: gamesLoading } = useQuery<GamesResponse>(gamesQuery, {
    variables: {
      input: {
        id: [...ids],
        devices: [device],
        limit: ids.length
      }
    },
    skip: !isAuth || ids.length === 0,
    fetchPolicy: 'no-cache'
  });

  const games = sortGamesByIds(ids, (data && data.games.success && data.games.items) || []);

  const notAuthed = authReady && !isAuth;
  const noGames = !games.length && !idsLoading && !gamesLoading;

  if (idsLoading || gamesLoading) return <GamesPaneSkeleton />;

  if (notAuthed || noGames) return null;

  return (
    <GamesTiles
      games={games}
      title={t('favorites')}
      href={`${RoutesConfig.casinoCategories}/favorites`}
      className={className}
    />
  );
};

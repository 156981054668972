export type Success = {
  success: true;
};

export type Error = {
  success: false;
  error: {
    message: string;
    stack?: string;
  };
};

export type Icon = {
  url: string;
  alt: string;
};

export type SeoConfigFields = {
  ogImage?: Icon;
  metaTitle?: string;
  metaDescription?: string;
  seoText?: string;
  titleH1?: string;
};

export type SeoConfigVariant =
  | 'seoLobbyConfig'
  | 'seoHomeConfig'
  | 'seoDefaultGameConfig'
  | 'seoSportsBookUrl'
  | 'seoDefaultSports'
  | 'seoDefaultSportsEvent'
  | 'seoDefaultTournament';

export enum BetType {
  prematch = 'prematch',
  live = 'live'
}

export type SeoResponse<T extends SeoConfigVariant> = {
  [key in T]: (Success & { data: SeoConfigFields }) | Error;
};

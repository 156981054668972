import { gql } from '@apollo/client';

export const getFavoritesQuery = gql`
  query GetFavorites {
    getFavorites {
      favorites
    }
  }
`;

export const toggleFavoritesMutation = gql`
  mutation ToggleFavorites($gameId: String!) {
    toggleFavorites(gameId: $gameId) {
      success
      error {
        message
        stack
      }
    }
  }
`;

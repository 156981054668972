import { Metadata } from 'next';
import { SeoConfigFields } from '../types';

export type GameVariables = Record<'game_name' | 'provider_name', string>;

export type SportVariables = Record<
  'sport_name' | 'tournament_name' | 'competitor1' | 'competitor2' | 'date',
  string
>;

export type SeoTextVariables = GameVariables | Partial<SportVariables>;

/*
replaceVariablesInTemplate
- в полученный текстовый шаблон с переменными вида %имя_переменной% подставляет значения переменных, переданных 
в виде {имя_переменной: значение_переменной}
*/

export const replaceVariablesInTemplate = <T extends SeoTextVariables>(
  template?: string,
  variables?: T
) => {
  if (!template || !variables) {
    return template;
  }

  const variablesNames = Object.keys(variables).join('|');
  const regEx = new RegExp(String.raw`%(${variablesNames})%`, 'gi');

  return template.replace(regEx, function (_unused, varName: keyof T) {
    return (variables[varName] as string) || '';
  });
};

/*
cleanFalsyProps
- вернет null, если все поля SeoConfigFields falsy.
*/

export const cleanFalsyProps = <T extends object>(obj?: T | null): T | null => {
  if (!obj) {
    return null;
  }

  const cleanedObj = Object.entries(obj).reduce(
    (acc, [k, v]) => (v && k !== '__typename' ? { ...acc, [k]: v } : acc),
    {} as T
  );

  return Object.keys(cleanedObj).length ? cleanedObj : null;
};

/*
collectSeoFields
- используется для генерации seo-полей из кастомных и дефолтных значений 
(при отсутствии соответсвующего кастомного поля используется дефолтное значение). Вернет null, если все поля пустые.
*/

export const collectSeoFields = (
  seoDefault: SeoConfigFields | null,
  seoCustom?: SeoConfigFields | null
): SeoConfigFields | null => {
  if (!seoDefault && !seoCustom) {
    return null;
  }

  const seo = {
    ...cleanFalsyProps(seoDefault),
    ...cleanFalsyProps(seoCustom)
  };

  return Object.keys(seo).length ? seo : null;
};

/*
buildMetaDataConfig
- из полученных seo-полей генерирует объект типа Metadata и подставляет в него значения переменных, переданных 
в виде {имя_переменной: значение_переменной}
*/

export const buildMetaDataConfig = (
  fields: SeoConfigFields | null,
  variables?: SeoTextVariables,
  canonical?: string
): Metadata | null => {
  if (fields) {
    const ogImage = fields.ogImage
      ? {
          url: fields.ogImage.url,
          alt: fields.ogImage.alt
        }
      : '';

    return cleanFalsyProps({
      title: fields.metaTitle && replaceVariablesInTemplate(fields.metaTitle, variables),
      description:
        fields.metaDescription && replaceVariablesInTemplate(fields.metaDescription, variables),
      openGraph: {
        images: ogImage && [ogImage]
      },
      alternates: {
        canonical
      }
    });
  }

  return null;
};
